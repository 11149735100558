var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"327d24d0f97bbd16950edbda9a16904ff3081c5f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { env } from './src/utils/env.mjs';

Sentry.init({
  tracesSampleRate: 0,
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  release: env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  environment: env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
});
